import { useState, useEffect, lazy, Suspense } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import Home from './Pages/Client/Home/Home';
import PageNotExist from './Pages/PageNotExist';
import Offer from './Pages/Client/Offer/Offer';
import PortfolioClient from './Pages/Client/Portfolio/PortfolioClient';
import Contact from './Pages/Client/Contact/Contact';
import PortfolioClientDetailed from './Pages/Client/Portfolio/PortfolioClientDetailed';
import { urlfunction } from './Utils/url';
import Tawkto from './Components/Tawkto';
import HeaderClient from './Layouts/HeaderClient';
import Loader from './Components/Loader';

const SkillsList = lazy(() => import('./Pages/Admin/Skills/SkillsList'));
const MainProfile = lazy(() => import('./Pages/Admin/Profile/MainProfile'));
const Experience = lazy(() => import('./Pages/Admin/Experience/Experience'));
const Portfolio = lazy(() => import('./Pages/Admin/Portfolio/Portfolio'));
const DetailProfile = lazy(() => import('./Pages/Admin/Profile/DetailProfile'));
const Leads = lazy(() => import('./Pages/Admin/Leads/leads'));
const EducationList = lazy(() =>
  import('./Pages/Admin/Education/EducationList')
);
const VideoList = lazy(() => import('./Pages/Admin/Video/VideoList'));
const PortfolioList = lazy(() =>
  import('./Pages/Admin/PortfolioList/PortfolioList')
);

const DynamicGeneration = lazy(() =>
  import('./Pages/Admin/DynamicGeneration/dynamicGeneration')
);
const SkillCategories = lazy(() =>
  import('./Pages/Admin/Category/CategoryList')
);

const Testimonials = lazy(() =>
  import('./Pages/Admin/Testimonials/TestimonialsList')
);

const SignIn = lazy(() => import('./Pages/Admin/Authentication/SignIn'));
const CV = lazy(() => import('./Pages/Client/CV/CV'));

const SuspenseWrapper = ({ children }) => (
  <Suspense
    fallback={
      <div className='my-5'>
        <Loader />
      </div>
    }
  >
    {children}
  </Suspense>
);

function App() {
  const parameter = useParams();
  const response = urlfunction(parameter);

  const uniqueID = response.path_unique_id;
  console.log(uniqueID);
  const [isVisible, setIsVisible] = useState(true);

  const location = useLocation();
  const token = localStorage.getItem('tokenbyLogin');
  const isAdminRoute = location.pathname.includes('/admin');
  const isCvRoute = location.pathname.includes('/cv');

  return (
    <>
      <div>
        {/* {!isAdminRoute && <Tawkto />} */}
        {!isAdminRoute && !isCvRoute ? (
          <HeaderClient isVisible={isVisible} setIsVisible={setIsVisible} />
        ) : (
          ''
        )}

        <Routes>
          {uniqueID.length < 5 && (
            <>
              <Route path={'/:' + uniqueID + '?/admin'}>
                {token ? (
                  <>
                    <Route
                      path=''
                      element={<Navigate replace to='profile' />}
                    />
                    <Route
                      path='profile'
                      element={
                        <SuspenseWrapper>
                          <MainProfile />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='skills'
                      element={
                        <SuspenseWrapper>
                          <SkillsList />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='experience'
                      element={
                        <SuspenseWrapper>
                          <Experience />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='portfolio'
                      element={
                        <SuspenseWrapper>
                          <Portfolio />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='detailprofile'
                      element={
                        <SuspenseWrapper>
                          <DetailProfile />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='leads'
                      element={
                        <SuspenseWrapper>
                          <Leads />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='education'
                      element={
                        <SuspenseWrapper>
                          <EducationList />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='list'
                      element={
                        <SuspenseWrapper>
                          <PortfolioList />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='dynamicGeneration'
                      element={
                        <SuspenseWrapper>
                          <DynamicGeneration />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='skillCategory'
                      element={
                        <SuspenseWrapper>
                          <SkillCategories />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='testimonials'
                      element={
                        <SuspenseWrapper>
                          <Testimonials />
                        </SuspenseWrapper>
                      }
                    />
                    <Route
                      path='videos'
                      element={
                        <SuspenseWrapper>
                          <VideoList />
                        </SuspenseWrapper>
                      }
                    />
                  </>
                ) : (
                  <Route
                    path=''
                    element={
                      <SuspenseWrapper>
                        <SignIn />
                      </SuspenseWrapper>
                    }
                  />
                )}
              </Route>

              <Route
                path={'/:' + uniqueID + '?/:u?'}
                element={
                  <Home setIsVisible={setIsVisible} isVisible={isVisible} />
                }
              />
              <Route
                path={'/:' + uniqueID + '?/cv/:u?'}
                element={
                  <SuspenseWrapper>
                    <CV />
                  </SuspenseWrapper>
                }
              />

              <Route
                path={'/:' + uniqueID + '?/offer/:u?'}
                element={
                  <Offer setIsVisible={setIsVisible} isVisible={isVisible} />
                }
              />
              <Route
                path={'/:' + uniqueID + '?/portfolio/:u?'}
                element={
                  <PortfolioClient
                    setIsVisible={setIsVisible}
                    isVisible={isVisible}
                  />
                }
              />

              <Route
                path={'/:' + uniqueID + '?/contact/:u?'}
                element={
                  <Contact setIsVisible={setIsVisible} isVisible={isVisible} />
                }
              />
              <Route
                path={'/:' + uniqueID + '?/portfolio/detail/:id/:u?'}
                element={
                  <PortfolioClientDetailed
                    setIsVisible={setIsVisible}
                    isVisible={isVisible}
                  />
                }
              />
              <Route path='*' element={<PageNotExist />} />
            </>
          )}
        </Routes>
      </div>
    </>
  );
}

export default App;
